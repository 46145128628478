* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    transition: all 250ms;
    user-select: none;
}

.share {
    border: 0px solid red;
}

.share_ {
    border: 0px solid red;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.shareButton {
    margin: 5px;
}

.shareIcon {
    border-radius: 6px;
}