* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    transition: all 250ms;
    user-select: none;
}

div.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 1em;
}

div.content::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
    border: 0px solid transparent;
}

div.content::-webkit-scrollbar-track {
    width: 0px;
    background-color: transparent;
    border: 0px solid transparent;
}

div.content::-webkit-scrollbar-thumb {
    width: 0px;
    background-color: silver;
    border: 0px solid transparent;
}

div.content_ {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    border: 0px solid red;
}

div.content_header {
    background-color: #073A6A;
    height: 120px;
}

div.content_header_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 0px 20px;
}

div.content_header_top_sentinelleLogo {
    flex: 1;
}

div.content_header_top_back {
    cursor: pointer;
}

div.content_header_top_userIcon {
    padding: 0px 10px 10px 10px;
    border: 0px solid red;
    cursor: pointer;
}

div.content_header_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 0px solid blue;
    margin-top: 5px;
}

div.content_header_bottom_ {
    display: inline-block;
    border: 0px solid red;
}

span.content_header_bottom_text {
    color: #fff;
    font-weight: bold;
    font-size: 1.3em;
}

div.content_header_bottom_barre {
    width: 35%;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
    margin: 0px auto;
    margin-top: 10px;
}

div.content_body {
    flex: 1;
    border: 0px solid green;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}