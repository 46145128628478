* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    transition: all 250ms;
    user-select: none;
}

div.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 1em;
}

div.content_ {
    width: 100%;
    height: auto;
}

div.content_top {
    width: 100%;
    max-width: 800px;
    margin: 0px auto;
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

div.content_top_icon {
    border: 2px solid #353D40;
    display: inline-block;
    border-radius: 100%;
    padding: 30px;
    margin: 0px auto;
}

div.content_top_text {
    margin-top: 10px;
    text-align: center;
}

span.content_top_text_ {
    font-size: 1.2em;
    font-weight: bold;
    color: #353D40;
    line-height: 1.3em;
    text-align: center;
}

div.content_body {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

div.content_body_ {
    width: 100%;
}

div.content_body_input {
    width: 100%;
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    margin-top: 20px;
}

span.content_body_input_label {
    font-size: 0.9em;
    line-height: 1.3em;
    color: #353D40;
}

input.content_body_input_balise {
    width: 100%;
    padding: 17px 10px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    font-size: 0.8em;
    outline: none;
    margin-top: 1px;
}

input.content_body_input_balise:placeholder {
    color: #DADADA;
}

span.content_body_input_subLabel {
    font-size: 0.9em;
    line-height: 1.3em;
    color: #353D40;
}

input.submit {
    background-color: #073A6A;
    color: #fff;
    cursor: pointer;
}

div.content_body_input2 {
    width: 100%;
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    margin-top: 10px;
}

input.content_body_input_connection {
    background-color: transparent;
    border: 0px solid #00000000;
    color: #353D40;
    line-height: 1.3em;
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
}