* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    transition: all 250ms;
    user-select: none;
}

div.dashboard {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 0px solid silver;
}

div.dashboard_content {
    border: 0px solid pink;
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

div.dashboard_content_ {
    border: 0px solid pink;
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

div.dashboard_content_noActivities_affiliateCode {
    border: 0px solid red;
    display: flex;
    justify-content: center;
}

div.dashboard_content_noActivities {
    padding-top: 20px;
}

div.dashboard_content_noActivities_title1 {
    color: #353D40;
    font-weight: 500;
    text-align: center;
    font-size: 1.1em;
    line-height: 1.2em;
    border: 0px solid red;
}

div.dashboard_content_noActivities_title2 {
    color: #000000aa;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 1.2em;
    margin-top: 10px;
    text-align: center;
    border: 0px solid red;
}

div.dashboard_content_noActivities_affiliateCode {
    margin-top: 30px;
}

div.dashboard_content_noActivities_affiliateCode_button {
    display: inline-flex;
    align-items: center;
    background-color: #DCDCDC;
    padding: 10px 40px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    margin: 0px auto;
}

div.dashboard_content_noActivities_affiliateCode_button_text {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 20px;
}

span.dashboard_content_noActivities_affiliateCode_button_text_top {
    color: #353D40;
    font-weight: 100;
    font-size: 0.8em;
}

input.dashboard_content_noActivities_affiliateCode_button_text_code {
    color: #353D40;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    background-color: transparent;
    border: 0px solid #00000000;
    outline: none;
    max-width: 150px;
    border: 0px solid red;
}

div.dashboard_content_noActivities_clickToCpyText {
    text-align: center;
    color: #353D40;
    font-size: 0.7em;
    margin-top: 10px;
    font-weight: 100;
}


/*ACtivities part style*/

div.dashboard_content_activities {
    padding-top: 20px;
    width: 100%;
    border: 0px solid red;
    padding: 0px 30px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

div.dashboard_content_activities_ {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 0px solid blue;
    overflow: hidden;
}

div.dashboard_content_activities_header {
    display: flex;
    width: 100%;
    padding-right: 10px;
}

div.dashboard_content_activities_header_item {
    width: 100%;
    flex: 1;
    color: #353D40aa;
    font-size: 0.9em;
    border-bottom: 0.3px solid #9AA9AE;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
}

div.inscriptions_item {
    text-align: center;
}

div.dashboard_content_activities_list {
    width: 100%;
    overflow-y: auto;
    padding-right: 10px;
    flex: 1;
    border: 0px solid red;
}

div.dashboard_content_activities_list::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    position: absolute;
    border-radius: 100px;
    background-color: transparent;
    border: 0px solid transparent;
}

div.dashboard_content_activities_list::-webkit-scrollbar-track {
    width: 0px;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 100px;
}

div.dashboard_content_activities_list::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 100px;
    background-color: #073A6A;
    border: 0px solid transparent;
    position: absolute;
}

div.dashboard_content_activities_list_item {
    display: flex;
    width: 100%;
    border-bottom: 0.3px solid #9AA9AE;
}

div.dashboard_content_activities_list_item_date {
    width: 100%;
    flex: 1;
    color: rgba(53, 61, 64, 0.91);
    font-size: 0.8em;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
}

div.dashboard_content_activities_list_item_inscriptions {
    width: 100%;
    flex: 1;
    color: rgba(53, 61, 64, 0.91);
    font-size: 0.8em;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
    text-align: center;
}

div.dashboard_content_activities_gains_ {
    background-color: #073A6A;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 10px 20px;
}

span.dashboard_content_activities_gains_text {
    font-size: 0.8em;
    line-height: 1.2em;
}

span.dashboard_content_activities_gains_solde {
    font-weight: bold;
    margin-top: 5px;
}